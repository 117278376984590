import { default as BN } from "bn.js";
import intervalToDuration from "date-fns/intervalToDuration";
import { CONTRACT_FOR_BNB } from "../../abis/constants";
import Web3 from "web3";


export const makeBNumber = (amount, decimal) => {
    const str = amount.toString();
    const isDecimal = str.includes('.');
    const decimalNumber = isDecimal ? str.split('.')[1].length : 0;
    let amountInt = parseInt(str.split('.').join(''));
    let decimalb = 10 ** (decimal - decimalNumber);
    if (decimalNumber > decimal) {
        amountInt = amountInt / (10 ** (decimalNumber - decimal));
        decimalb = 1;
    }
    
    const decimals = new BN(decimalb.toString());
    const bn = new BN(new BN(amountInt).mul(decimals));
    return bn;
}

export const parseBNumber = (amount, decimal) => {
    let decimalb = 10 ** decimal;
    const value = amount / decimalb;
    return value;
}

export const queryUrl = (url, params) => {
    const paths = [];
    for (const key of Object.keys(params)) {
        const atomStr = params[key] ? `${key}=${params[key]}` : `${key}`;
        paths.push(atomStr);
    }
    return `${url}?${paths.join('&')}`;
}

export const readableNumber = (bigNum) => {
    const str = bigNum.toString();
    if (str.includes('e+')) {
        const splits = str.split('e+');
        const temp = parseFloat(splits[0]).toFixed(2).toString();
        splits[0] = temp;
        return splits.join('e+');
    } else {
        return bigNum.toFixed(2);
    }
}

export const parseDateTime = (timestamp) => {
    const dateTime = new Date(timestamp);
    const y = dateTime.getFullYear();
    const m = ('00' + (dateTime.getMonth() + 1)).slice(-2);
    const d = ('00' + dateTime.getDate()).slice(-2);
    const hh = ('00' + dateTime.getHours()).slice(-2);
    const mm = ('00' + dateTime.getMonth()).slice(-2);
    const ss = ('00' + dateTime.getSeconds()).slice(-2);
    return `${y}-${m}-${d} ${hh}:${mm}:${ss}`;
}

export const formatDate = (dateStr) => {
    const monthName=['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    if (!dateStr) return '';
    const dateTime = new Date(dateStr);
    const y = dateTime.getFullYear();
    const m = dateTime.getMonth();
    const d = dateTime.getDate();

    return `${monthName[m]}. ${d}, ${y}`;
}

export const calRemainDate = (endDate) => {
    const diff = intervalToDuration({
        start: new Date(),
        end: new Date(endDate)
    });

    let result = [];
    if (diff.years !== 0) result.push(`${diff.years} years`);
    if (diff.months !== 0) result.push(`${diff.months} months`);
    if (diff.days !== 0) result.push(`${diff.days} days`);

    return result.join(', ');
}

export const getBNBPrice = async () => {
    const web3 = new Web3('https://bsc-dataseed1.binance.org:443');
    const bnbContract = new web3.eth.Contract(CONTRACT_FOR_BNB.abi, CONTRACT_FOR_BNB.address);
    const balanceInfo = await bnbContract.methods.getReserves().call();
    const busdAmount = parseBNumber(balanceInfo._reserve1, 18);
    const bnbAmount = parseBNumber(balanceInfo._reserve0, 18);
    const bnbPrice = busdAmount / bnbAmount;

    return bnbPrice;
}