import React from 'react';
import styles from './styles.module.scss';
import {
    CircularProgressbar,
    CircularProgressbarWithChildren,
    buildStyles
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

const Chart = (props) => {
    const { depositRatio, softcapRatio } = props;
    return (
        <div className={styles.root}>
            <CircularProgressbarWithChildren
                value={0}
                strokeWidth={3}
                styles={buildStyles({
                    pathColor: "#34858f",
                    trailColor: "#34858f",
                    strokeLinecap: "butt"
                })}
            >
                <div style={{width: 208, height: 208}}>
                    <CircularProgressbarWithChildren
                        value={softcapRatio}
                        strokeWidth={6}
                        styles={buildStyles({
                            pathColor: "#43adb1",
                            trailColor: "transparent",
                            strokeLinecap: "butt"
                        })}
                    >
                        {/* Foreground path */}
                        <div style={{ width: 214, height: 214 }}>
                            <CircularProgressbar
                                value={depositRatio}
                                strokeWidth={9}
                                text={`${depositRatio}%`}
                                styles={buildStyles({
                                    pathColor: "#7af8ff",
                                    trailColor: "transparent",
                                    strokeLinecap: "butt",
                                    textColor: "#fff",
                                })}
                            />
                        </div>
                    </CircularProgressbarWithChildren>
                </div>
            </CircularProgressbarWithChildren>
        </div>
    )
}

export default Chart;