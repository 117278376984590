import React from "react";
import PulseLoader from "react-spinners/PulseLoader";

const Loader = () => {
    return (
        <div className="">
            <PulseLoader size={15} color="#e5e5e5"/>
        </div>
    )
}

export default Loader;