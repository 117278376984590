import React from 'react';
import './Footer.css';

const Footer = () => {
    return(
        <div className='root'>
            <div className='link-container'> 
                <a className='link' href="https://www.onino.io/documents/ONINO_Risk_Disclaimer.pdf" target="_blank" rel="noreferrer">
                    Risk Disclaimer
                </a>
                <a className='link' href="https://www.onino.io/documents/ONINO_Terms_and_Conditions_Website.pdf" target="_blank" rel="noreferrer">
                    Terms of Use
                </a>
                <a className='link' href="https://www.onino.io/documents/ONINO_Terms_and_Conditions_Products_Services.pdf" target="_blank" rel="noreferrer">
                    Terms & Conditions
                </a>
                <a className='link' href="https://www.onino.io/documents/ONINO_Privacy_Policy.pdf" target="_blank" rel="noreferrer">
                    Privacy Policy
                </a>
                <a className='link' href="https://www.onino.io/documents/ONINO_Cookies_Policy.pdf" target="_blank" rel="noreferrer">
                    Cookies Policy
                </a>
            </div>
        </div>
    )
}

export default Footer;