import PresaleContractABI from './PresaleContract.json';
import BNB_ABI from './BNB_ABI.json';

// export const presaleContract = {
//     netId: 97,
//     network: 'testnet',
//     // address: '0xdeB630f0869AB3399f0082e12fbD16a3Cb5C0994',
//     // address: '0xc8828A7054C9f34767Cd265Fc995341FcdA2542b',
//     address: '0x0da0e3DcBeF15c767C8B645e6dA57BbCA3bb7fE6',
//     abi: PresaleContractABI
// }

export const presaleContract = {
    netId: 56,
    network: 'bscscan',
    address: '0x6996F61EB8c8017114a4404cF5fc5fCd957edB5E',
    abi: PresaleContractABI
}

export const CONTRACT_FOR_BNB = {
    address: '0x58f876857a02d6762e0101bb5c46a8c1ed44dc16',
    abi: BNB_ABI
}