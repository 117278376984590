import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Routes from './routes';
import './App.css';
import './assets/css/global.scss';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Sidebar from './components/Sidebar';
import Header from './components/Header';
import Footer from './components/Footer';

toast.configure();

function App() {
  return (
    <Router>
      <Header/>
      <Sidebar/>
      <Routes />
      <Footer />
    </Router>
  );
}

export default App;
