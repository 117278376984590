import React from 'react';
import Web3 from "web3";
import WalletConnectProvider from "@walletconnect/web3-provider";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MetamaskIcon from '../../assets/images/metamask.png';
import TrustIcon from '../../assets/images/trust.png';
import Wlconnect from '../../assets/images/wlconnect.png';
import CloseIcon from '../../assets/images/close-icon.svg';
import { changeChainNet } from '../../services/contract';
import { presaleContract } from '../../abis/constants';
import styles from './styles.module.scss';
import classNames from 'classnames';

toast.configure();

// const useStyles = makeStyles(theme => ({
//     container: {
//         paddingLeft: 24,
//         paddingRight: 24,
//         width: '100%',
//         maxWidth: 500
//     },
//     wrapper: {
//         backgroundColor: '#131517',
//         borderRadius: 16
//     },
//     header: {
//         display: 'flex',
//         justifyContent: 'space-between',
//         alignItems: 'center',
//         padding: '12px 0 12px 24px',
//     },
//     title: {
//         color: '#ffffff',
//         fontFamily: 'Montserrat, sans-serif',
//         fontSize: 20,
//         fontWeight: 500
//     },
//     body: {
//         padding: '0 16px 16px'
//     },
//     walletdiv: {
//         backgroundColor: '#212329',
//         border: '1px solid #31343E',
//         display: 'flex',
//         justifyContent: 'space-between',
//         alignItems: 'center',
//         height: 60,
//         borderRadius: 12,
//         padding: '0 15px',
//         marginTop: 16,
//         cursor: 'pointer'
//     },
//     walletLabel: {
//         fontFamily: 'Montserrat, sans-serif',
//         fontSize: 16,
//         fontWeight: 400,
//         color: '#ffffff'
//     },
//     icondiv: {
//         width: 27,
//         height: 27
//     },
//     walletIcon: {
//         width: '100%',
//     }
// }))

export default function WalletPopup(props) {
    const { setOpenPopup, setCurrentProvider, setUserAccount } = props;
    const netId = presaleContract.netId;
    const network = presaleContract.network;

    const connectMetamask = async () => {
        if (window.ethereum) {
            if (window.ethereum.isMetaMask) {
               connect();
            } else {
                toast.error("Please install Metamask first", {autoClose: 5000, theme: 'colored', position: 'top-center'});  
            }
        } else {
            toast.error("Please install Metamask first", {autoClose: 5000, theme: 'colored', position: 'top-center'});
        }
    }

    const connectTrustWallet = async () => {
        if (window.ethereum) {
            if (window.ethereum.isMetaMask) {
                toast.error("Please install Trust wallet and open the website on Trust/DApps", {autoClose: 5000, theme: 'colored', position: 'top-center'});  
            } else {
                connect();
            }
        } else {
            toast.error("Please install Trust wallet and open the website on Trust/DApps", {autoClose: 5000, theme: 'colored', position: 'top-center'});
        }
    }

    const connect = async () => {
        if (window.ethereum) {
            setCurrentProvider(window.ethereum);
            localStorage.setItem('walletType', 'METAMASK_CHROME');

            const web3 = new Web3(window.ethereum);
            
            try {
                await window.ethereum.request({ method: "eth_requestAccounts" });
                const accounts = await window.ethereum.request({ method: 'eth_accounts' });
                setUserAccount((accounts && accounts[0]) || '');
                localStorage.setItem('walletType', 'METAMASK_CHROME');
                setOpenPopup(false);

                const networkId = await web3?.eth?.net?.getId();
                if (networkId !== netId) await changeChainNet();

                return;
            } catch (error) {
                console.log(error);
            }
        }
    }

    const connectWalletConnect = async () => {
        try {
            const walletConnectProvider = new WalletConnectProvider({
                rpc: {
                    56: 'https://bsc-dataseed.binance.org/',
                    97: 'https://data-seed-prebsc-1-s1.binance.org',
                    25: 'https://evm-cronos.crypto.org'
                },
                chainId: netId,
                network: network,
            });
            
            setCurrentProvider(walletConnectProvider);
            // localStorage.setItem('provider', walletConnectProvider);
            await walletConnectProvider.enable();
        
            const web3 = new Web3(walletConnectProvider);
            const accounts = await web3.eth.getAccounts();
            const currentAddr = accounts[0];
            setUserAccount(currentAddr);
            localStorage.setItem('walletType', 'WALLET_CONNECT');
            setOpenPopup(false);
        } catch (err) {
            console.log(err);
        }
    }

    return (
        <div className={styles.root}>
            <div className={styles.container}>
                    <div className={styles.header}>
                        <h4>Connect to a wallet</h4>
                        <div className='cursor-pointer'>
                            <img src={CloseIcon} alt="" onClick={() => setOpenPopup(false)}/>
                        </div>
                    </div>
                    <div className={styles.body}>
                        <div
                            className={classNames('flex justify-between items-center', styles.walletItem)}
                            onClick={() => connectMetamask()}
                        >
                            <h5 className={styles.walletLabel}>Metamask</h5>
                            <div className={styles.walletIcon}>
                                <img src={MetamaskIcon} alt="" className={styles.walletIcon}/>
                            </div>
                        </div>
                        <div
                            className={classNames('flex justify-between items-center', styles.walletItem)}
                            onClick={() => connectTrustWallet()}
                        >
                            <h5 className={styles.walletLabel}>Trust wallet</h5>
                            <div className={styles.walletIcon}>
                                <img src={TrustIcon} alt="" className={styles.walletIcon}/>
                            </div>
                        </div>
                        <div
                            className={classNames('flex justify-between items-center', styles.walletItem)}
                            onClick={() => connectWalletConnect()}
                        >
                            <h5 className={styles.walletLabel}>WalletConnect</h5>
                            <div className={styles.walletIcon}>
                                <img src={Wlconnect} alt="" className={styles.walletIcon}/>
                            </div>
                        </div>
                    </div>
            </div>
            <div className={styles.overlay} onClick={() => setOpenPopup(false)}/>
        </div>
    )
}