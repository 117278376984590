import React from 'react';
import styles from './styles.module.scss';
import Circles1 from '../../assets/images/circles1.svg';
import Circles2 from '../../assets/images/circles2.svg';
import Circles3 from '../../assets/images/circles3.svg';

const Background = () => {
    return (
        <div className={styles.root}>
            <img src={Circles1} alt="" className={styles.circles1}/>
            <img src={Circles2} alt="" className={styles.circles2}/>
            <img src={Circles3} alt="" className={styles.circles3}/>
        </div>
    )
}

export default Background;