import React from 'react';
import styles from './styles.module.scss';
import TwitterIcon from '../../assets/images/twitter.png';
import TelegramIcon from '../../assets/images/telegram.png';
import InstagramIcon from '../../assets/images/instagram.svg';
import MediumIcon from '../../assets/images/medium.svg';

const Sidebar = () => {
    return (
        <div className={styles.root}>
            <div className={styles.vertical_mode}>
                <div>
                    <a href="https://medium.com/@OninoOfficial" target={'_blank'} rel="noopener noreferrer">
                        <img src={MediumIcon} alt="" style={{paddingLeft: 4}}/>
                    </a>
                    <a href="https://www.instagram.com/onino.io/" target={'_blank'} rel="noopener noreferrer">
                        <img src={InstagramIcon} alt="" style={{paddingLeft: 4}}/>
                    </a>
                    <a href="https://t.me/oninoofficial" target={'_blank'} rel="noopener noreferrer">
                        <img src={TelegramIcon} alt="" />
                    </a>
                    <a href="https://twitter.com/onino_io" target={'_blank'} rel="noopener noreferrer">
                        <img src={TwitterIcon} alt="" />
                    </a>
                    <span>{'Join our Community'}</span>
                </div>
            </div>
        </div>
    )
}

export default Sidebar;