// import setAuthToken from '../utils/set-auth-token';

export const actionTypes = {
    RefreshCount: '[RefreshCount] Action',
    Web3Client: '[Web3Client] Action',
};

const initialState = {
    refreshCount: 0,
    web3Client: null,
};

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.RefreshCount: {
            return {
                ...state,
                refreshCount: state.refreshCount + 1,
            };
        }
        case actionTypes.Web3Client: {
            return {
                ...state,
                web3Client: action.payload,
            };
        }
        default:
            return state;
    }
};

export const actions = {
    changeRefresh: () => ({
        type: actionTypes.RefreshCount,
    }),
    setWeb3: (data) => ({
        type: actionTypes.Web3Client,
        payload: data
    }),
};
