import React from 'react';
import styles from './styles.module.scss';
import HeaderOninoLogo from '../../assets/images/header-onino-logo.svg';

const Header = () => {
    return (
        <div className={styles.root}>
            <a href="https://www.onino.io/" target="_blank" rel="noreferrer">
                <img src={HeaderOninoLogo} alt="" />
            </a>
            <p className='text-sm'>Official Pre Sale</p>
        </div>
    )
}

export default Header;
