import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import Home from '../pages/Home';
// import ScrollIntoView from '../components/ScrollIntoView';

function Routes() {
    return (
        // <ScrollIntoView>
            <Switch>
                <Route exact path={'/'} component={Home}/>
                <Redirect to={'/'} />
            </Switch>
        // </ScrollIntoView>
    )
}

export default Routes;
