import Web3 from "web3";
import WalletConnectProvider from "@walletconnect/web3-provider";
import { presaleContract } from '../../abis/constants';

export const getWeb3Client = async () => {
    const netId = presaleContract.netId;
    const network = presaleContract.network
    const walletType = localStorage.getItem('walletType');

    if (walletType === 'WALLET_CONNECT') {
        const walletConnectProvider = new WalletConnectProvider({
            rpc: {
                56: 'https://bsc-dataseed.binance.org/',
                97: 'https://data-seed-prebsc-1-s1.binance.org',
                25: 'https://evm-cronos.crypto.org'
            },
            chainId: netId,
            network: network
        });
        await walletConnectProvider.enable();
        window.web3 = new Web3(walletConnectProvider);
    } else if (window.ethereum) {
        window.web3 = new Web3(window.ethereum);
        console.log("You have a modern web3 browser!");
    } else {
        console.log("no ethereum browser");
        if (netId === 56) {
            window.web3 = new Web3('https://bsc-dataseed1.binance.org:443');
        } else if (netId === 25) {
            window.web3 = new Web3('https://evm-cronos.crypto.org:443');
        } else {
            window.web3 = new Web3('https://data-seed-prebsc-1-s1.binance.org:8545');
        }
    }

    const web3 = window.web3;
    return web3;
}

export const changeChainNet = async () => {
    if (window.ethereum) {
        const bscNet = [{
            chainId: '0x38',
            chainName: 'Binance Smart Chain',
            nativeCurrency: {
                name: 'BNB',
                symbol: 'BNB',
                decimals: 18
            },
            rpcUrls: ['https://bsc-dataseed.binance.org/'],
            blockExplorerUrls: ['https://bscscan.com/'],
        }]
        
        /* eslint-disable */
        const tx = await window.ethereum.request({ method: 'wallet_addEthereumChain', params: bscNet }).catch();
        if (tx) {
            console.log(tx)
        }
    }
}