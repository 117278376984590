import React, { useCallback, useEffect, useRef, useState } from 'react';
import Web3 from "web3";
// import WalletConnectProvider from "@walletconnect/web3-provider";
import classnames from 'classnames';
import styles from './styles.module.scss';
import Chart from '../../components/Chart';
import Background from '../../components/Background';
import WalletPopup from '../../components/WalletPopup';
import OninoIcon from '../../assets/images/onino-icon.svg';
import WithdrawIcon from '../../assets/images/withdraw.svg';
import { presaleContract } from '../../abis/constants';
import { changeChainNet, getWeb3Client } from '../../services/contract';
import {
    formatDate,
    // getBNBPrice,
    makeBNumber,
    parseBNumber,
    readableNumber
} from '../../services/utils';
import { toast } from 'react-toastify';
import Loader from '../../components/Loader';
import { useSelector, useDispatch } from 'react-redux';
import { actions } from '../../redux/common.redux';
import classNames from 'classnames';
import TermsPopup from '../../components/TermsPopup';


const Home = () => {
    const refreshCnt = useSelector(state => state.common.refreshCount);
    const web3Client = useSelector(state => state.common.web3Client);
    const dispatch = useDispatch();

    // const [bnbId, setBnbId] = useState(-1);
    const [bnbAmount, setBnbAmount] = useState(0);
    // const [oniAmount, setOniAmount] = useState(0);
    // const [web3Client, setWeb3Client] = useState(null);
    const [userAccount, setUserAccount] = useState(null);
    const [currentProvider, setCurrentProvider] = useState(null);
    const [psContract, setPsContract] = useState(null);
    const [totalDeposit, setTotalDeposit] = useState(0);
    const [softcapBnb, setSoftcapBnb] = useState(0);
    const [hardcapBnb, setHardcapBnb] = useState(0);
    const [minBnb, setMinBnb] = useState(0);
    const [maxBnb, setMaxBnb] = useState(0);
    const [rateBnb, setRateBnb] = useState(0);
    // const [bnbPrice, setBnbPrice] = useState(0);
    const [depositRatio, setDepositRatio] = useState(0);
    const [softcapRatio, setSoftcapRatio] = useState(0);
    const [totalPurchased, setTotalPurchased] = useState(0);
    const [withdrawn, setWithdrawn] = useState(false);
    const [openWalletPopup, setOpenWalletPopup] = useState(false);
    const [openTermsPopup, setOpenTermsPopup] = useState(false);
    const [status, setStatus] = useState('NOT_CONNECTED');
    const [timerStatus, setTimerStatus] = useState(0); // 0 < start < 1 < end < 2 < release < 3
    const [limitTime, setLimitTime] = useState('');
    const [loadingDeposit, setLoadingDeposit] = useState(false);
    const [loadingWithdraw, setLoadingWithdraw] = useState(false);
    const [agreed, setAgreed] = useState(false);
    const [country, setCountry] = useState('0');

    const countries = [
        "Afghanistan",
        "Albania",
        "American Samoa",
        "Andorra",
        "Angola",
        "Anguilla",
        "Antarctica",
        "Antigua and Barbuda",
        "Argentina",
        "Armenia",
        "Aruba",
        "Australia",
        "Austria",
        "Azerbaijan",
        "Bahamas (the)",
        "Barbados",
        "Belarus",
        "Belgium",
        "Belize",
        "Benin",
        "Bermuda",
        "Bhutan",
        "Bonaire, Sint Eustatius and Saba",
        "Bosnia and Herzegovina",
        "Botswana",
        "Bouvet Island",
        "Brazil",
        "British Indian Ocean Territory (the)",
        "Brunei Darussalam",
        "Bulgaria",
        "Burkina Faso",
        "Burma",
        "Cambodia",
        "Canada",
        "Cape Verde",
        "Cayman Islands (the)",
        "Chad",
        "Chile",
        "Christmas Island",
        "Cocos (Keeling) Islands (the)",
        "Colombia",
        "Comoros (the)",
        "Congo (the Democratic Republic)",
        "Congo (the)",
        "Cook Islands (the)",
        "Costa Rica",
        "Croatia",
        "Cuba",
        "Curaçao",
        "Cyprus",
        "Czechia",
        "Côte d'Ivoire",
        "Denmark",
        "Djibouti",
        "Dominica",
        "Dominican Republic (the)",
        "East Timor",
        "El Salvador",
        "Equatorial Guinea",
        "Eritrea",
        "Estonia",
        "Eswatini",
        "Ethiopia",
        "Falkland Islands (the) [Malvinas]",
        "Faroe Islands (the)",
        "Fiji",
        "Finland",
        "France",
        "French Guiana",
        "French Polynesia",
        "French Southern Territories (the)",
        "Gambia (the)",
        "Germany",
        "Ghana",
        "Gibraltar",
        "Greece",
        "Greenland",
        "Grenada",
        "Guadeloupe",
        "Guam",
        "Guatemala",
        "Guernsey",
        "Guinea",
        "Guinea-Bissau",
        "Haiti",
        "Heard Island and McDonald Islands",
        "Holy See (the)",
        "Honduras",
        "Hong Kong",
        "Hungary",
        "Iceland",
        "India",
        "Indonesia",
        "Iran (Islamic Republic of)",
        "Ireland",
        "Isle of Man",
        "Israel",
        "Italy",
        "Jamaica",
        "Japan",
        "Jersey",
        "Jordan",
        "Kazakhstan",
        "Kenya",
        "Kiribati",
        "Korea (the Democratic People's Republic of)",
        "Korea (the Republic of)",
        "Kosovo",
        "Kyrgyzstan",
        "Lao People's Democratic Republic (the)",
        "Latvia",
        "Lebanon",
        "Liberia",
        "Liechtenstein",
        "Lithuania",
        "Luxembourg",
        "Madagascar",
        "Malawi",
        "Malaysia",
        "Mali",
        "Malta",
        "Marshall Islands (the)",
        "Martinique",
        "Mauritania",
        "Mauritius",
        "Mayotte",
        "Mexico",
        "Micronesia (Federated States of)",
        "Moldova (the Republic of)",
        "Monaco",
        "Mongolia",
        "Montenegro",
        "Montserrat",
        "Mozambique",
        "Myanmar",
        "Namibia",
        "Nauru",
        "Netherlands (the)",
        "New Caledonia",
        "New Zealand",
        "Nicaragua",
        "Niger (the)",
        "Nigeria",
        "Niue",
        "Norfolk Island",
        "Northern Mariana Islands (the)",
        "Norway",
        "Oman",
        "Pakistan",
        "Palau",
        "Palestine, State of",
        "Panama",
        "Papua New Guinea",
        "Paraguay",
        "Peru",
        "Philippines (the)",
        "Pitcairn",
        "Poland",
        "Portugal",
        "Puerto Rico",
        "Republic of North Macedonia",
        "Romania",
        "Russian Federation (the)",
        "Rwanda",
        "Réunion",
        "Saint Barthélemy",
        "Saint Helena, Ascension and Tristan da Cunha",
        "Saint Kitts and Nevis",
        "Saint Lucia",
        "Saint Martin (French part)",
        "Saint Pierre and Miquelon",
        "Saint Vincent and the Grenadines",
        "Samoa",
        "San Marino",
        "Sao Tome and Principe",
        "Saudi Arabia",
        "Senegal",
        "Serbia",
        "Seychelles",
        "Sierra Leone",
        "Singapore",
        "Sint Maarten (Dutch part)",
        "Slovakia",
        "Slovenia",
        "Solomon Islands",
        "Somalia",
        "South Africa",
        "South Georgia and the South Sandwich Islands",
        "South Sudan",
        "Spain",
        "Sri Lanka",
        "Sudan (the)",
        "Suriname",
        "Svalbard and Jan Mayen",
        "Swaziland",
        "Sweden",
        "Switzerland",
        "Syrian Arab Republic",
        "Taiwan",
        "Tajikistan",
        "Tanzania, United Republic of",
        "Thailand",
        "Timor-Leste",
        "Togo",
        "Tokelau",
        "Tonga",
        "Trinidad and Tobago",
        "Turkmenistan",
        "Turks and Caicos Islands (the)",
        "Tuvalu",
        "Uganda",
        "Ukraine",
        "United Arab Emirates (the)",
        "United Kingdom of Great Britain and Northern Ireland (the)",
        "United States Minor Outlying Islands (the)",
        "Uruguay",
        "Uzbekistan",
        "Vanuatu",
        "Venezuela (Bolivarian Republic of)",
        "Virgin Islands (British)",
        "Virgin Islands (U.S.)",
        "Wallis and Futuna",
        "Western Sahara",
        "Yemen",
        "Zambia",
        "Åland Islands",
    ]

    // const bnbList = [0.2, 0.3, 0.4, 0.5];
    const decimals = 18;

    // Timer
    let tmr = useRef();
    let duration = useRef(-1000);
    const [remainDay, setRemainDay] = useState('0');
    const [remainHour, setRemainHour] = useState('00');
    const [remainMinutes, setRemainMinutes] = useState('00');
    const [remainSecond, setRemainSecond] = useState('00');

    // const getTimes = useCallback(async () => {
    //     const web3 = await getWeb3Client();
    //     const presale_contract = new web3.eth.Contract(presaleContract.abi, presaleContract.address);
    //     // const start_time = await presale_contract.methods._START_TIME().call();
    //     // const end_time = await presale_contract.methods._END_TIME().call();
    //     const release_time = await presale_contract.methods._RELEASE_TOKEN_TIME().call();
    //     duration.current = release_time * 1000 - new Date().getTime();
    // }, [])

    useEffect(() => {
        // getTimes();
        // Time downCounter
        if (!tmr.current) {
            tmr.current = setInterval(async () => {
                if (duration.current <= -1000) {
                    duration.current = -1000;
                    setRemainDay('0');
                    setRemainHour('00');
                    setRemainMinutes('00');
                    setRemainSecond('00');
                } else {
                    const duration_in_sec = Math.round(duration.current / 1000);
                    const day = Math.floor(duration_in_sec / 86400);
                    const hor = Math.floor((duration_in_sec % 86400) / 3600);
                    const min = Math.floor((duration_in_sec % 3600) / 60);
                    const sec = duration_in_sec % 60;
                    setRemainDay(day);
                    setRemainHour(('00' + hor).slice(-2));
                    setRemainMinutes(('00' + min).slice(-2));
                    setRemainSecond(('00' + sec).slice(-2));
                    duration.current -= 1000;
                }
            }, 1000)
        }

        return () => {
            clearInterval(tmr.current);
        }

    }, [])

    const initProcess = useCallback(async () => {
        try {
            const web3 = web3Client ? web3Client : await getWeb3Client();
            // setWeb3Client(web3);

            if (!web3) return;

            const networkId = await web3?.eth?.net?.getId();
            if (networkId !== presaleContract.netId) await changeChainNet();

            const walletType = localStorage.getItem('walletType');
            const accounts = await web3?.eth?.getAccounts();
            const account = walletType ? accounts?.[0] : null;
            setUserAccount(account);

            // const bnb_price = await getBNBPrice();
            // setBnbPrice(bnb_price);

            const presale_contract = new web3.eth.Contract(presaleContract.abi, presaleContract.address);
            setPsContract(presale_contract);

            const softcap = await presale_contract.methods._SOFTCAP().call();
            const softcap_bnb = parseBNumber(softcap, decimals)
            setSoftcapBnb(softcap_bnb);

            const hardcap = await presale_contract.methods._HARDCAP().call();
            const hardcap_bnb = parseBNumber(hardcap, decimals);
            setHardcapBnb(hardcap_bnb);

            const total_deposit = await presale_contract.methods._TOTAL_DEPOSIT_BNB().call();
            const deposit = parseBNumber(total_deposit, decimals);
            setTotalDeposit(deposit);

            // const initTime = 1640955011000;
            // const diff = new Date().getTime() - initTime;
            // if (diff > 7200000) {
            //     setTotalDeposit(hardcap_bnb);
            //     const deposit_ratio = (hardcap_bnb) * 100 / hardcap_bnb;
            //     setDepositRatio(deposit_ratio.toFixed(2));
            // } else if (diff > 7200000 - 600000) {
            //     setTotalDeposit(hardcap_bnb - 4.5);
            //     const deposit_ratio = (hardcap_bnb - 4.5) * 100 / hardcap_bnb;
            //     setDepositRatio(deposit_ratio.toFixed(2));
            // } else if (diff > 7200000 - 1200000) {
            //     setTotalDeposit(hardcap_bnb - 9);
            //     const deposit_ratio = (hardcap_bnb - 9) * 100 / hardcap_bnb;
            //     setDepositRatio(deposit_ratio.toFixed(2));
            // } else if (diff > 7200000 - 1800000) {
            //     setTotalDeposit(hardcap_bnb - 13.5);
            //     const deposit_ratio = (hardcap_bnb - 13.5) * 100 / hardcap_bnb;
            //     setDepositRatio(deposit_ratio.toFixed(2));
            // } else if (diff > 7200000 - 2400000) {
            //     setTotalDeposit(hardcap_bnb - 18);
            //     const deposit_ratio = (hardcap_bnb - 18) * 100 / hardcap_bnb;
            //     setDepositRatio(deposit_ratio.toFixed(2));
            // } else if (diff > 7200000 - 3000000) {
            //     setTotalDeposit(hardcap_bnb - 22.5);
            //     const deposit_ratio = (hardcap_bnb - 22.5) * 100 / hardcap_bnb;
            //     setDepositRatio(deposit_ratio.toFixed(2));
            // } else if (diff > 7200000 - 3600000) {
            //     setTotalDeposit(hardcap_bnb - 27);
            //     const deposit_ratio = (hardcap_bnb - 27) * 100 / hardcap_bnb;
            //     setDepositRatio(deposit_ratio.toFixed(2));
            // } else if (diff > 7200000 - 4200000) {
            //     setTotalDeposit(hardcap_bnb - 31.5);
            //     const deposit_ratio = (hardcap_bnb - 31.5) * 100 / hardcap_bnb;
            //     setDepositRatio(deposit_ratio.toFixed(2));
            // } else if (diff > 7200000 - 4800000) {
            //     setTotalDeposit(hardcap_bnb - 36);
            //     const deposit_ratio = (hardcap_bnb - 36) * 100 / hardcap_bnb;
            //     setDepositRatio(deposit_ratio.toFixed(2));
            // } else if (diff > 7200000 - 5400000) {
            //     setTotalDeposit(hardcap_bnb - 40.5);
            //     const deposit_ratio = (hardcap_bnb - 40.5) * 100 / hardcap_bnb;
            //     setDepositRatio(deposit_ratio.toFixed(2));
            // } else if (diff > 7200000 - 6000000) {
            //     setTotalDeposit(hardcap_bnb - 45);
            //     const deposit_ratio = (hardcap_bnb - 45) * 100 / hardcap_bnb;
            //     setDepositRatio(deposit_ratio.toFixed(2));
            // } else if (diff > 7200000 - 6600000) {
            //     setTotalDeposit(hardcap_bnb - 49.5);
            //     const deposit_ratio = (hardcap_bnb - 49.5) * 100 / hardcap_bnb;
            //     setDepositRatio(deposit_ratio.toFixed(2));
            // } else if (diff > 0) {
            //     setTotalDeposit(deposit);
            //     const deposit_ratio = (deposit) * 100 / hardcap_bnb;
            //     setDepositRatio(deposit_ratio.toFixed(2));
            // }

            const softcap_ratio = hardcap_bnb !== 0 ? softcap_bnb * 100 / hardcap_bnb : 0;
            setSoftcapRatio(softcap_ratio.toFixed(2));

            // const deposit = hardcap_bnb;
            // setTotalDeposit(deposit);

            const deposit_ratio = hardcap_bnb !== 0 ? deposit * 100 / hardcap_bnb : 0;
            setDepositRatio(deposit_ratio.toFixed(2));

            const min_bnb = await presale_contract.methods._MIN_BNB_CONTRIBUTION().call();
            setMinBnb(parseBNumber(min_bnb, decimals));

            const max_bnb = await presale_contract.methods._MAX_BNB_CONTRIBUTION().call();
            setMaxBnb(parseBNumber(max_bnb, decimals));

            const rate_bnb = await presale_contract.methods._RATE().call();
            setRateBnb(rate_bnb);

            const start_time = await presale_contract.methods._START_TIME().call();
            const end_time = await presale_contract.methods._END_TIME().call();
            const release_time = await presale_contract.methods._RELEASE_TOKEN_TIME().call();

            if (start_time * 1000 >= new Date().getTime()) {
                duration.current = start_time * 1000 - new Date().getTime();
                setLimitTime(formatDate(start_time * 1000));
                setTimerStatus(0);
            } else if (end_time * 1000 >= new Date().getTime()) {
                duration.current = end_time * 1000 - new Date().getTime();
                setLimitTime(formatDate(end_time * 1000));
                setTimerStatus(1);
                // setSaleStarted(true);
            } else if (release_time * 1000 >= new Date().getTime()) {
                duration.current = release_time * 1000 - new Date().getTime();
                setLimitTime(formatDate(release_time * 1000));
                setTimerStatus(2);
                // setSaleEnded(true);
            } else {
                // setReleased(true);
                setLimitTime(formatDate(release_time * 1000));
                setTimerStatus(3);
            }

            if (!account) return;

            const whitelisted = await presale_contract.methods.isPresaleWhitelist(account).call();
            setStatus(whitelisted ? 'WHITELISTED' : 'NOT_WHITELISTED');

            const total_purchased = await presale_contract.methods.totalPurchased(account).call();
            setTotalPurchased(parseBNumber(total_purchased, decimals));

            console.log('-------------------------------------')
            console.log('account: ', account)
            console.log('purchased bnb: ', parseBNumber(total_purchased, decimals))
            console.log('ratebnb: ', rate_bnb)
            console.log('purchased oni = bnb * ratebnb: ', parseBNumber(total_purchased, decimals) * rate_bnb)

            const withdrawStatus = await presale_contract.methods.withdrawn(account).call();
            setWithdrawn(withdrawStatus);

        } catch (err) {
            console.log(err);
        }

    }, [web3Client])

    useEffect(() => {
        initProcess();
    }, [initProcess, refreshCnt])

    useEffect(() => {
        const interval = setInterval(() => {
            initProcess();
        }, 10000);

        return () => clearInterval(interval);
    }, [initProcess])

    useEffect(() => {
        if (window.ethereum) {
            window.ethereum.on('accountsChanged', function (accounts) {
                const walletType = localStorage.getItem('walletType');
                if (walletType) {
                    setUserAccount(accounts[0]);
                    localStorage.setItem('walletType', 'METAMASK_CHROME');
                }
            });
        }
        // return () => {
        //     window.ethereum.removeListener('accountsChanged', function () { });
        // }
    }, [])

    useEffect(() => {
        const walletType = localStorage.getItem('walletType');
        if (walletType && currentProvider) {
            const web3 = new Web3(currentProvider);
            dispatch(actions.setWeb3(web3));
            // setWeb3Client(web3);
        }
    }, [currentProvider, dispatch]);

    // const selectBnb = (id) => {
    //     setBnbId(id);
    //     setBnbAmount(bnbList[id]);
    // }

    const contribute = async () => {
        if (!agreed) {
            toast.error('You have to agree the Terms & Conditions', { autoClose: 3000, theme: 'colored' });
            return;
        }
        try {
            if (userAccount) {
                if (bnbAmount < minBnb || bnbAmount > maxBnb) {
                    toast.error(`Invalid bnb value. min value is ${minBnb} and max value is ${maxBnb}.`, { autoClose: 5000, theme: 'colored' });
                } else if (country === '0') {
                    toast.error(`Please select your country.`, {autoClose: 5000, theme: 'colored'});
                } else {
                    setLoadingDeposit(true);
                    const stakeAmount = makeBNumber(bnbAmount, decimals);
                    await psContract?.methods?.deposite().send({
                        from: userAccount,
                        value: stakeAmount
                    });
                    // await psContract?.methods?.deposite(bnbAmount).send({from: userAccount});

                    setLoadingDeposit(false);
                    dispatch(actions.changeRefresh());
                    setStatus('DEPOSITED');
                }
            } else {
                toast.error('Please connect wallet', { autoClose: 3000, theme: 'colored' });
            }

        } catch (err) {
            setLoadingDeposit(false);
            console.log(err);
        }
    }

    const withdraw = async () => {
        if (timerStatus < 3) {
            toast.error(`You can withdraw after ${limitTime}`, { autoClose: 3000, theme: 'colored' });
            return;
        }
        if (userAccount) {
            try {
                setLoadingWithdraw(true);
                await psContract?.methods?.withdraw().send({ from: userAccount });

                setLoadingWithdraw(false);
                dispatch(actions.changeRefresh());
                setStatus('WITHDRAWN');

            } catch (err) {
                setLoadingWithdraw(false);
                console.log(err);
            }
        } else {
            toast.error('Please connect wallet', { autoClose: 3000, theme: 'colored' });
        }
    }

    return (
        <div className={styles.root}>
            <Background />
            <div className={styles.container}>
                <div className={styles.saleSection}>
                    <div className={styles.chartBox}>
                        <h5 className='text-19 text-white text-bold'>
                            Amount raised <br />in Pre Sale
                        </h5>
                        <div className={styles.chart}>
                            <Chart depositRatio={depositRatio} softcapRatio={softcapRatio} />
                        </div>
                        <div className={styles.markWrapper}>
                            <div>
                                <div className={classnames(styles.mark, styles.raised)} />
                                <div className='text-15 text-white'>
                                    <span className='text-bold'>{readableNumber(totalDeposit)} BNB</span><br />
                                    <span>Raised</span>
                                </div>
                            </div>
                            <div>
                                <div className={classnames(styles.mark, styles.softCap)} />
                                <div className='text-15 text-white'>
                                    <span className='text-bold'>{readableNumber(softcapBnb)} BNB</span><br />
                                    <span>Soft cap</span>
                                </div>
                            </div>
                            <div>
                                <div className={classnames(styles.mark, styles.target)} />
                                <div className='text-15 text-white'>
                                    <span className='text-bold'>{readableNumber(hardcapBnb)} BNB</span><br />
                                    <span>Target</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    {timerStatus >= 2 ? (
                        <div className={styles.saleBox}>
                            <h6 className='text-15 pb-6'>Thank you for participating in the Pre Sale.</h6>
                            <p className='text-12 pb-1'>You now own:</p>
                            <div className={styles.badge}>
                                <img src={OninoIcon} alt="" className='mr-3 ml-7' />
                                <span className='text-12'>{readableNumber(totalPurchased * rateBnb)} ONI Tokens</span>
                            </div>
                            <p className='text-12 pb-6'>You can withdraw the tokens after the ICO <br />(Approx. {limitTime})</p>
                            {!userAccount ? (
                                <div className={styles.btn} onClick={() => setOpenWalletPopup(true)}>
                                    Connect wallet to withdraw
                                </div>
                            ) : (
                                <>
                                    {!withdrawn ? (
                                        <div
                                            className={classNames(styles.outlineBtn, timerStatus === 3 ? 'opacity-100' : 'opacity-50')}
                                            onClick={() => withdraw()}
                                        >
                                            {loadingWithdraw ? (
                                                <Loader />
                                            ) : (
                                                <>
                                                <span>Withdraw</span>
                                                {timerStatus < 3 && (
                                                    <img src={WithdrawIcon} alt="" className='ml-4'/>
                                                )}
                                                </>
                                            )}
                                        </div>
                                    ) : (
                                        <h5 className='text-16 text-bold text-center'>Already withdrawn.</h5>
                                    )}
                                </>
                            )}
                        </div>
                    ) : (
                        <div className={styles.saleBox}>
                            <div className='pb-7'>
                                <div className='flex-1'>
                                    <p className='text-14 text-bold'>Minimum contribution: {minBnb} BNB</p>
                                </div>
                                <div className='flex-1'>
                                    <p className='text-14 text-bold'>Maximum contribution: {maxBnb} BNB</p>
                                </div>
                            </div>
                            <div className='flex items-center pb-7'>
                                <div className='flex-1'>
                                    <p className='text-14 text-bold'>Deposited BNB: <br/>{readableNumber(totalPurchased)}</p>
                                </div>
                                <div className='flex-1'>
                                    <p className='text-14 text-bold'>ONI Token Amount: <br/>{readableNumber(totalPurchased * rateBnb)}</p>
                                </div>
                            </div>
                            <div className={styles.tokenWrapper}>
                                <div>
                                    <p className='text-12 pb-2'>BNB</p>
                                    <div className={styles.labelBox}>
                                        <input type="number" value={bnbAmount} onChange={(e) => setBnbAmount(e.target.value)} />
                                    </div>
                                </div>
                                <div>
                                    <p className='text-12 pb-2'>ONI</p>
                                    <div className={styles.labelBox}>
                                        <p className='text-12'>{(bnbAmount * rateBnb).toFixed(2)}</p>
                                    </div>
                                </div>
                            </div>
                            {/* <div className={classnames(styles.selectBnb, 'mt-4')}>
                                <div
                                    className={classnames(
                                        styles.labelBox,
                                        'cursor-pointer',
                                        bnbId === 0 ? styles.bgPrimary : ''
                                    )}
                                    onClick={() => selectBnb(0)}
                                >
                                    <span className='text-12'>0.2 BNB</span>
                                </div>
                                <div
                                    className={classnames(
                                        styles.labelBox,
                                        'cursor-pointer',
                                        bnbId === 1 ? styles.bgPrimary : ''
                                    )}
                                    onClick={() => selectBnb(1)}
                                >
                                    <span className='text-12'>0.3 BNB</span>
                                </div>
                                <div
                                    className={classnames(
                                        styles.labelBox,
                                        'cursor-pointer',
                                        bnbId === 2 ? styles.bgPrimary : ''
                                    )}
                                    onClick={() => selectBnb(2)}
                                >
                                    <span className='text-12'>0.4 BNB</span>
                                </div>
                                <div
                                    className={classnames(
                                        styles.labelBox,
                                        'cursor-pointer',
                                        bnbId === 3 ? styles.bgPrimary : ''
                                    )}
                                    onClick={() => selectBnb(3)}
                                >
                                    <span className='text-12'>0.5 BNB</span>
                                </div>
                            </div> */}

                            <div style={{position: 'relative'}}>
                                <div className={styles.selectWrapper}>
                                    <select
                                        value={country}
                                        onChange={(e) => setCountry(e.target.value)}
                                    >
                                        <option value="0" disabled>Select your country</option>
                                        {countries.map((item, idx) => {
                                            return (
                                                <option value={item} key={idx}>{item}</option>
                                            )
                                        })}
                                    </select>
                                </div>
                                <p className={styles.tooltip}>We must ask for your country for legal reasons.</p>
                            </div>

                            <div className='flex items-center py-6'>
                                <input type="checkbox" checked={agreed} onChange={() => setAgreed(!agreed)} />
                                <div onClick={() => setOpenTermsPopup(true)}>
                                    <p className='text-12 ml-2 cursor-pointer'>I have read the Terms & Conditions</p>
                                </div>
                            </div>
                            {!userAccount && (
                                <div className={styles.btn} onClick={() => setOpenWalletPopup(true)}>
                                    Connect wallet to stake
                                </div>
                            )}
                            {userAccount && status === 'WHITELISTED' && (
                                <>
                                    {timerStatus === 0 && (
                                        <span className='text-16 text-bold text-center'>You are whitelisted.</span>
                                    )}
                                    {timerStatus === 1 && (
                                        <>
                                            {totalPurchased < 2.0 ? (
                                                <div className={styles.btn} onClick={() => contribute()}>
                                                    {loadingDeposit ? (
                                                        <Loader />
                                                    ) : (
                                                        <span>You are whitelisted. ✓ &nbsp; Please Contribute.</span>
                                                    )}
                                                </div>
                                            ) : (
                                                <p className='text-16 text-bold text-center'>You have already contributed the maximum amount. Thank you!</p>
                                            )}
                                        </>
                                    )}
                                </>
                            )}
                            {userAccount && status === 'NOT_WHITELISTED' && (
                                <>
                                    <div className={styles.btn}>
                                        Try connecting a different wallet address
                                    </div>
                                    <a href="https://docs.google.com/forms/d/e/1FAIpQLSdY5lLbS59YXCjVhJpRrw1EoV5_R_sA7-3NCzxZxj4rS_2zwg/viewform?usp=sf_link" target='_blank' rel="noopener noreferrer"
                                        className={classNames('text-center mt-5', styles.link)}
                                    >
                                        <span className='text-16'>Or join our waiting list here</span>
                                    </a>
                                </>
                            )}
                        </div>
                    )}
                </div>
                <div className={styles.timerSection}>
                    {timerStatus === 0 && (
                        <p className='text-12 text-center mb-9'>Pre Sale starts in {limitTime}</p>
                    )}
                    {timerStatus === 1 && (
                        <p className='text-12 text-center mb-9'>Pre Sale ends in {limitTime}</p>
                    )}
                    {timerStatus === 2 && (
                        <p className='text-12 text-center mb-9'>Tokens will be released in {limitTime}</p>
                    )}

                    <div className='flex items-center justify-around'>
                        <div className='text-center'>
                            <h4 className='text-30 text-bold'>{remainDay}</h4>
                            <p className='text-12'>Days</p>
                        </div>
                        <div className='text-center'>
                            <h4 className='text-30 text-bold'>{remainHour}</h4>
                            <p className='text-12'>Hours</p>
                        </div>
                        <div className='text-center'>
                            <h4 className='text-30 text-bold'>{remainMinutes}</h4>
                            <p className='text-12'>Minutes</p>
                        </div>
                        <div className='text-center'>
                            <h4 className='text-30 text-bold'>{remainSecond}</h4>
                            <p className='text-12'>Seconds</p>
                        </div>
                    </div>
                </div>
            </div>
            {/* {loading && (
                <Spinner />
            )} */}

            {openWalletPopup && (
                <WalletPopup
                    setOpenPopup={setOpenWalletPopup}
                    setUserAccount={setUserAccount}
                    setCurrentProvider={setCurrentProvider}
                />
            )}
            {openTermsPopup && (
                <TermsPopup
                    setOpenPopup={setOpenTermsPopup}
                    setAgreed={setAgreed}
                />
            )}
        </div>
    )
}

export default Home;
